 
 <template>    
  <div class="logo" >
    <a-image
    :src="logoUrl"
    :preview="false"
    />
  </div>
</template>

<script>
import { toRefs, reactive} from "vue"
import { Image } from 'ant-design-vue';
export default ({
  components: {
    AImage: Image
  },
  name:"Logo",
  setup() {
      const data = reactive({
          logoUrl: "/images/logo.png",
      });
      
    return {
      ...toRefs(data),
    };
  },
});
</script>
