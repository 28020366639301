<template>
  <div class="env-tips-wrap" v-if="visible">
    <div class="env-tips">
      <span class="text-error">{{ $t("common.version_tips") }}</span>
      <span class="tips-close" v-if="showClose" @click="handleClose">
        <CloseOutlined class="text-error" />
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    // 是否显示：为false的情况下，就算是非正式环境都不会显示
    isShow: {
      type: Boolean,
      default: true
    },
    // 是否显示关闭按钮
    showClose: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const state = reactive({
      visible: false,
    });

    const handleClose = () => {
      state.visible = false;
    };

    onMounted(() => {
      if (process.env.VUE_APP_WEB_ENV !== 'PROD' && props.isShow) {
        state.visible = true;
      }
    });

    return {
      ...toRefs(state),
      handleClose,
    };
  },
});
</script>

<style lang="less" scoped>
.env-tips-wrap {
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: 99999;
}
.env-tips {
  position: relative;
  padding: 2px;
  border-left: 1px solid rgba(255, 0, 0, 0.5);
  border-right: 1px solid rgba(255, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 0, 0, 0.5);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  font-size: 12px;
  user-select: none;
  text-align: center;

  display: flex;
  align-items: center;
  & > span:first-child {
    flex: 1;
    padding: 0 30px;
  }
  span.tips-close {
    cursor: pointer;
    right: 0;
  }
}
</style>
