import siteConfigs from "@/siteconfigs/index.js"
import { getLocalLang } from '@/utils/common';
import { extensionKeys } from "../../consts/index";

const state = {
  /**
   * 主题
   * light 白色
   * dark 暗色
   */
    theme: localStorage.getItem("conf-theme") == null
    ? (siteConfigs.theme ==null ? "theme-dark" : siteConfigs.theme) 
    : localStorage.getItem("conf-theme"),

    /**
     * 布局
     * side 侧边
     * head 顶部
     */
    layout: localStorage.getItem("conf-layout") == null
    ? (siteConfigs.layout==null ? "layout-side" : siteConfigs.layout) 
    : localStorage.getItem("conf-layout"),

    /**
     * 主题颜色
     */
    color: localStorage.getItem("conf-color") == null
    ? (siteConfigs.color==null ? "" : siteConfigs.color) 
    : localStorage.getItem("conf-color"),

    /**
     * 侧边展开状态
     */
    collapsed: localStorage.getItem("conf-collapsed") == null
    ? (siteConfigs.collapsed==null ? false : siteConfigs.collapsed) 
    : localStorage.getItem("conf-collapsed"),

    /**
     * 多标签页面
     */
    tab:localStorage.getItem("conf-tab") == null
    ? (siteConfigs.tab==null ? true : siteConfigs.tab) 
    : localStorage.getItem("conf-tab"),

    /**
     * 保持状态
     */
    keepAlive: siteConfigs.keepAlive,

    /**
     * 固定头部
     */
    fiexdHeader:localStorage.getItem("conf-fixed-header") == null
    ? (siteConfigs.fiexdHeader==null ? false : siteConfigs.fiexdHeader) 
    : localStorage.getItem("conf-fixed-header"),

    /**
     * 路由动画
     * fadeTop
     * 
     */
    routerAnimate:localStorage.getItem("conf-router-animate") == null
    ? (siteConfigs.routerAnimate == null ? "fadeTop" : siteConfigs.routerAnimate) 
    : localStorage.getItem("conf-router-animate"),
    /**
     * 是否是移动端
     */ 
    isMobile: false,

    // 路由刷新
    routerActive: true,

    // 路由表
    routes: [],

    /**
     * 本地化
     */
    language: getLocalLang(siteConfigs.defaultLanguage) || "zh-CN",

    cancelToken: []
}

const mutations = {

  //UPDATE
  UPDATE_THEME(state, theme) {
    state.theme = theme;
  },
  UPDATE_LAYOUT(state, layout) {
    state.layout = layout;
  },
  UPDATE_COLOR(state, color) {
    state.color = color;
  },
  UPDATE_TAB(state) {
    state.tab = !state.tab;
  },
  UPDATE_ISMOBILE(state, isMobile) {
    state.isMobile = isMobile;
  },
  UPDATE_ROUTER_ANIMATE(state, routerAnimate) {
    state.routerAnimate = routerAnimate;
  },
  UPDATE_ROUTER_ACTIVE(state, isActive) {
    state.routerActive = isActive;
  },


  //TOGGLE
  TOGGLE_COLLAPSED(state) {
    state.collapsed = !state.collapsed;
  },
  TOGGLE_KEEP_ALIVE(state) {
    state.keepAlive = !state.keepAlive;
  },
  TOGGLE_FIXED_HEADER(state) {
    state.fixedHeader = !state.fixedHeader;
  },
  TOGGLE_LANGUAGE(state, language) {
    state.language = language;
    localStorage.setItem(extensionKeys.confLang, language)
  },


  // CANCEL TOKEN
  PUSH_CANCEL_TOKEN(state, payload) {
    state.cancelToken.push(payload.cancelToken)
  },
  EXEC_CANCEL_TOKEN(state) {
    state.cancelToken.forEach(executor => {
      executor('Route has been changed. Cancel previous requests.')
    })
    state.cancelToken = []
  },

}

const actions = {
  execCancelToken({ commit }) {
    return new Promise(resolve => {
      commit('EXEC_CANCEL_TOKEN');
      resolve()
    })
  },
  setLanguage({ commit }, payload) {
    commit('TOGGLE_LANGUAGE', payload)
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  