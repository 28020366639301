/// 基础路由
export default [
  {
    path: "/",
    redirect: "/home",
    hidden: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/accounts/Login.vue"),
    meta: {
      title: "Login",
      disAuth: true,
      text:"common.login"
    },
    hidden: true,
  },
  {
      path:"/centerlayout",
      name:"CenterLayout",
      component: () => import("@/views/layout/CenterLayout.vue"),
      hidden:true,
      children:[
        {
          path: "/register",
          name: "Register",
          component: () => import("@/views/accounts/Register.vue"),
          meta: {
            title: "Register",
            disAuth: true,
            text:"menu.register"
          },
          hidden: true,
        },
        {
          path: "/resetpwd",
          name: "ResetPwd",
          component: () => import("@/views/accounts/ResetPwd.vue"),
          meta: {
            title: "ResetPwd",
            disAuth: true,
          },
          hidden: true,
        },
        {
          path: "/apply",
          name: "Apply",
          component: () => import("@/views/accounts/Apply.vue"),
          meta: {
            title: "Apply",
            text:"menu.company_info"
          },
          hidden: true,
        },
        {
          path: "/auth",
          name: "Auth",
          component: () => import("@/views/accounts/Auth.vue"),
          meta: {
            title: "Auth",
            text:"menu.auth"
          },
          hidden: true,
        },
      ]
  },
  {
    path:"/publiclayout",
    name:"PublicLayout",
    component: () => import("@/views/layout/PublicLayout.vue"),
    hidden: true,
    children:[
      {
        path: "/home",
        name: "public_home",
        component: () => import("@/views/public/Home.vue"),
        meta: {
          title: "Home",
          disAuth: true,
          text: "public.home_page",
        },
        hidden: false,
      },
      {
        path: "/selogistics",
        name: "public_se_logistics",
        component: () => import("@/views/public/SeLogistics.vue"),
        meta: {
          title: "SE Logistics",
          disAuth: true,
          text: "public.se_logistics",
        },
        hidden: false,
      },
      {
        path: "/sewarehouse",
        name: "public_se_warehouse",
        component: () => import("@/views/public/SeWarehouse.vue"),
        meta: {
          title: "SE Overseas Warehouse",
          disAuth: true,
          text: "public.se_overseas_warehouse",
        },
        hidden: false,
      },
      {
        path: "/Sefinance",
        name: "public_se_finance",
        component: () => import("@/views/public/SeFinance.vue"),
        meta: {
          title: "SE Finance",
          disAuth: true,
          text: "public.se_finance",
        },
        hidden: false,
      },
      {
        path: "/aboutus",
        name: "public_about_us",
        component: () => import("@/views/public/AboutUs.vue"),
        meta: {
          title: "About Us",
          disAuth: true,
          text: "public.about_us",
        },
        hidden: false,
      },
      {
        path: "/privacy",
        name: "public_privacy",
        component: () => import("@/views/public/Privacy.vue"),
        meta: {
          title: "Privacy",
          disAuth: true,
          text: "public.privacy",
        },
        hidden: false,
      },
    ]
  },
  {
    path: "/Layout",
    name: "Layout",
    component: () => import("@/views/layout/Index.vue"),
    meta: {
      title: "Layout",
    },
    hidden: true,
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/error/Error.vue"),
    meta: {
      title: "Error",
    },
    hidden: true,
    children: [
      {
        path: "403",
        name: "403",
        meta: {
          title: "403",
        },
        component: () => import("@/views/error/403.vue"),
      },
      {
        path: "404",
        name: "404",
        meta: {
          title: "404",
        },
        component: () => import("@/views/error/404.vue"),
      },
      {
        path: "500",
        name: "500",
        meta: {
          title: "500",
        },
        component: () => import("@/views/error/500.vue"),
      },
    ],
  },
];
