import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
//import Home from "../views/Home.vue";
import { navGuards } from "@/router/guards";
import baseRoutes from "./module/base-routes"


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes:baseRoutes,
});

router.beforeEach(navGuards);
export default router;
