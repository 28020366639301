import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const affixTouteNames = ref(["dashboard_index"])

const visitedRoutes = ref([]);

const pathEquals = (path1,path2)=>{
  if(path1==null || path2 == null){
    return false;
  }
  return (path1+"").toLowerCase() ===( path2+"").toLowerCase();
}

function isAffixRoute(routeOrName){
  if(typeof(routeOrName) === "string"){
    return affixTouteNames.value.findIndex(at=>at == routeOrName)>=0;
  }
  return affixTouteNames.value.findIndex(at=>at == routeOrName.name)>=0;
}

function addVisitedRoute(route){
  let existed = visitedRoutes.value.find((item)=>pathEquals(item.path,route.path));
  if(existed){
    if (!pathEquals(route.fullPath, existed.fullPath)) {
      Object.assign(existed, route)
    }
    return;
  }
  visitedRoutes.value.push(Object.assign({}, route));
}

function delVisitedRoute(route) {
  
  visitedRoutes.value.forEach((item, index) => {
    if (pathEquals(item.path,route.path) && !isAffixRoute(item)) {
      visitedRoutes.value.splice(index, 1)
    }
  })
}

function delOthersRoutes(route){
  visitedRoutes.value = visitedRoutes.value.filter((item)=>{
    return isAffixRoute(item)
    || pathEquals(item.path,route.path);
    // return item.name == fixedRouteName || item.path == route.path;
  });
}

function delAllRoutes(){
  visitedRoutes.value = visitedRoutes.value.filter((item)=>{
    return isAffixRoute(item);
    // return item.name == fixedRouteName;
  });
}

function updateRoute(route){
  visitedRoutes.value.forEach((item)=>{
    if (pathEquals(item.path,route.path)) item = Object.assign(item, route)
  });
}


export function useTab() {
  
  return {
    pathEquals,
    isAffixRoute,
    addVisitedRoute,
    delVisitedRoute,
    delOthersRoutes,
    delAllRoutes,
    updateRoute,
    visitedRoutes
  };
}