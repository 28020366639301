import { headerKeys, nameKeys,extensionKeys } from "../../consts/index";

export function getToken() {
  return localStorage.getItem(nameKeys.token);
}
export function getLang() {
  return localStorage.getItem(extensionKeys.confLang);
}


export function getTimezone() {
  return localStorage.getItem(nameKeys.timezone);
}

export default {
  getToken,
  getTimezone,
};
