import { createStore } from "vuex";
import app from "./modules/app.js"
import user from "./modules/user.js"
import getters from './getters.js'

export default createStore({
  state: {
  },
  modules: {
    app,
    user},
  getters,
  mutations: {},
  actions: {},
});
