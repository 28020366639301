import axios from "axios";
import urlHelper from "@/utils/urlHelper.js"

const queryPartnerKey = "pt";
const queryTagKey = "tag";
const localstoragePartnerKey = "_partner_pt";
const localstorageTagKey = "_partner_tag";

// 1.null 
// 2. { pt:required, url:required, tag:optional }
export const getQueryRefer = () => {
    try {
        var url = window.location.href;
        var pt = urlHelper.getQueryVal(url, queryPartnerKey);
        if (pt) {
            var result = {
                pt: pt,
                url: url,
            }
            var tag = urlHelper.getQueryVal(url, queryTagKey);
            if (tag) {
                result["tag"] = tag;
            }
            return result;
        }
    } catch {

    }
    return null;
}

//设置Partner信息
export const trySetReferInfo = (overwriteBefore = false) => {
    if (typeof window != "undefined") {
        try {
            var sotragedPartnerKey = localStorage.getItem(localstoragePartnerKey);
            if (!overwriteBefore && sotragedPartnerKey) {
                return;
            }
            var queryReferInfo = getQueryRefer();
            if(!queryReferInfo){
                return;
            }
            if(queryReferInfo.pt){
                localStorage.setItem(localstoragePartnerKey, queryReferInfo.pt);
                if(queryReferInfo.tag){
                    localStorage.setItem(localstorageTagKey, queryReferInfo.tag);
                }
            }
            
            
            // var url = window.location.href;
            // var pt = urlHelper.getQueryVal(url, queryPartnerKey);
            // if (pt) {
            //     //存储
            //     localStorage.setItem(localstoragePartnerKey, pt);
            //     var tag = urlHelper.getQueryVal(url, queryTagKey);
            //     if (tag) {
            //         //存储
            //         localStorage.setItem(localstorageTagKey, tag);
            //     }
            // }
        } catch {
            //ignore
        }
    }
}

//返回Partner相关信息, tag可能为空
export const tryGetReferInfo = () => {
    var sotragedPartnerKey = localStorage.getItem(localstoragePartnerKey);
    if (!sotragedPartnerKey) {
        return null;
    }
    var partnerInfo = {
        pt: sotragedPartnerKey,
        tag: localStorage.getItem(localstorageTagKey)
    };
    return partnerInfo;
}