<template>
  <div :class="[tabNeedPadding && 'header-tabs-p']">
    <div class="tabs-wrap">
      <div class="tabs-panes">
        <a-tabs
          class="small-header-tabs tabs"
          v-model:activeKey="selectedKey"
          type="editable-card"
          @edit="onTabsEdit"
          @change="onTabsChange"
          hideAdd
          :size="'small'"
        >
          <a-tab-pane
            v-for="item in visitedRoutes"
            :key="item.path"
            :tab="$t(item.meta.text || item.meta.title || '')"
            :closable="!isAffixRoute(item)"
          ></a-tab-pane>
        </a-tabs>
      </div>
      <div class="tabs-actions">
        <a-dropdown placement="bottomRight">
          <DownOutlined />
          <template #overlay>
            <a-menu>
              <a-menu-item @click="closeCurrent">{{ $t("common.close_current") }}</a-menu-item>
              <a-menu-item @click="closeOthers">{{ $t("common.close_others") }}</a-menu-item>
              <a-menu-item @click="closeAll">{{ $t("common.close_all") }}</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { watch, computed, reactive, toRefs, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useTab } from '@/hooks/tabs/index';
import { Tabs, Dropdown, Menu } from 'ant-design-vue';
import { useStore } from "vuex";
export default ({
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item
  },
  setup() {
    const { getters, commit, dispatch } = useStore();

    const tabNeedPadding = computed(() => {
      return !(getters.isMobile)
    })
    let selectedKey = ref("");
    const {
      pathEquals,
      isAffixRoute,
      addVisitedRoute,
      delVisitedRoute,
      delOthersRoutes,
      delAllRoutes,
      updateRoute,
      visitedRoutes
    } = useTab();

    let route = useRoute();
    let router = useRouter();

    const initTabs = () => {
      router.getRoutes().forEach(item => {
        if (isAffixRoute(item)) {
          addVisitedRoute(item);
        }
      });
    }

    const addTabs = () => {
      const { name } = route;
      if (name) {
        addVisitedRoute(route)
      }
      return false;
    };

    watch(() => route.fullPath, (newFullPath) => {
      initTabs();
      addTabs();
      selectedKey.value = "";
      for (var i = 0; i < visitedRoutes.value.length; i++) {
        if (pathEquals(visitedRoutes.value[i].path, route.path)) {
          selectedKey.value = visitedRoutes.value[i].path;
          break;
        }
      }
    }, { immediate: true });

    const isActive = (paraRoute) => {
      return paraRoute && pathEquals(paraRoute.path, route.path);
    }

    const closeCurrent = () => {
      delVisitedRoute(route);
      toLastTab();
    };

    const closeOthers = () => {
      delOthersRoutes(route);
      toLastTab();
    };

    const closeAll = () => {
      delAllRoutes(route);
      toLastTab();
    };

    const onTabsEdit = (targetKey, action) => {
      if (action === 'add') {
        //add
      } else {
        // remove
        let item;
        for (var i = 0; i < visitedRoutes.value.length; i++) {
          if (visitedRoutes.value[i].path == targetKey) {
            item = visitedRoutes.value[i];
            break;
          }
        }
        if (item) {
          delVisitedRoute(item);
        }
        if (isActive(item)) {
          toLastTab();
        }
      }

    };

    const onTabsChange = (targetKey) => {
      const targetRoute = visitedRoutes.value.find((item, index) => {
        return pathEquals(item.path, targetKey);
      })
      if (targetRoute) {
        router.push(targetRoute)
      }
    }
    const toLastTab = () => {
      const latestView = visitedRoutes.value.slice(-1)[0]
      if (latestView) {
        router.push(latestView)
      } else {
        router.push('/')
      }
    };
    return {
      tabNeedPadding,
      selectedKey,
      visitedRoutes,
      isAffixRoute,
      onTabsEdit,
      onTabsChange,
      closeCurrent,
      closeOthers,
      closeAll
    };
  },
});
</script>
 <style lang="less">
.small-header-tabs.ant-tabs.ant-tabs-card {
  .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 30px;
  }
  .ant-tabs-card-bar .ant-tabs-tab-active {
    border: 1px solid @primary-color;
  }
  .ant-tabs-card-bar .ant-tabs-tab {
    height: 30px;
    line-height: 28px;
    border-radius: @border-radius-base;
  }
  .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
    border-bottom: none;
  }
  // .ant-tabs-nav.ant-tabs-nav-animated{
  //   margin-left: 15px;
  // }
  .ant-tabs-bar {
    margin-bottom: 5px;
  }
}
.header-tabs-p {
  padding: 0 16px;
}
.tabs-wrap {
  display: flex;
  line-height: 28px;
  .tabs-panes {
    flex: 1;
    overflow: hidden;
  }
  .tabs-actions {
    width: 20px;
    line-height: 28px;
  }
}
</style>
