import axios from "axios";
import siteConfig from "../siteconfigs/index";
import { headerKeys } from "../consts/index";
import { getToken, getLang } from "../hooks/login/index";

import router from "../router/index";
import { showErrorMsg } from "./errorHandlers";

class Client {
  constructor(config) {
    this.config = config || {
      timeout: 180000,
      // withCredentials: true,
      baseURL: siteConfig.baseURL,
      headers: {
        "Content-Type": "application/json; charset=utf-8",

      },
      withoutAuth:false,//不提交token认证
    };
  }
  interceptors(instance) {
    /**
     * 请求拦截器
     */
    instance.interceptors.request.use(
      (config) => {
        if (!config.withoutAuth) {
          const token = getToken();
          if (token) {
            config.headers[headerKeys.authorization] =
              headerKeys.bearer + " " + token;
          }
        }
        
        const culture = getLang();
        if(culture){
          config.headers[headerKeys.customCulture] = culture;
        }
        // 请求时缓存该请求，路由跳转时取消, 如果timeout值过大，可能在上一个次请求还没完成时，切换了页面。
        config.cancelToken = new axios.CancelToken(async (cancel) => {
          // await store.dispatch("app/execCancelToken", { cancelToken: cancel });
        });
        
        if (config.data == undefined) {
          config.data = {};
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        let { data, config = {} } = response;
        if (data && data.code == 0) {
          return Promise.resolve(data);
        } else {
          if (!config.disableAutoError) {
            showErrorMsg(data);
          }
          return Promise.reject(
            data || {
              code: -1,
            }
          );
        }
      },
      (error) => {
        let { config = {} } = error;
        if (error.response) {
          let {
            response: { status },
          } = error;
          let data = error.response.data || {};

          if (!config.disableAutoError) {
            showErrorMsg(data);
          }
          if(!config.disableAutoRedirect){
            let currentRoute = router.currentRoute?.value??{};
            let query = {};
            var returnUrl = currentRoute?.fullPath;
            if(currentRoute?.name != "Login" && returnUrl && !(returnUrl||"").startsWith("/error")){
              // query["returnurl"] = encodeURIComponent(returnUrl);
              query["returnurl"] = returnUrl;
            }
            if (status === 401) {
              // to 401 error page
              router.push({name:"Login", query: query});
            } else if (status === 403) {
              // to 403 error page
              // router.push({name:"Login"})
              router.push({path:"/error/403",query: query})
            } else if (status === 404) {
              // to 404 error page
              router.push({path:"/error/404",query: query})
            } else if (status === 500) {
              // to 500 error page
              // router.push("/error/500")
            }
          }
        } else {
          // let { message } = error;
          if (!config.disableAutoError) {
            showErrorMsg();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    const requestOptions = Object.assign({}, this.config, options);
    this.interceptors(instance);
    return instance(requestOptions);
  }
}
const client = new Client();

export default client;
