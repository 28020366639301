import client from "../client"
import settings from "../../siteconfigs/index.js"
import { method } from "lodash"
import { request } from "http"

const Api = {
  getPublicKey:"/api/Login/GetPublicKey",
  login:"/api/Login/RequestToken",
  logout:"/api/ProductService/Account/LogOut",
  getMenus:"/api/getMenus",
  getUserInfo:"/api/ProductService/Account/GetShopUserWithShop",
  getMenusOfUser: "/api/URCSecurityService/Security/GetGreaterThanFunctionMenuOfUser",
  addReferPageView:"/api/SupplierService/PartnerLabel/CumulativeVisitsCount"
}

export const getPublicKey = () => {
  return client.request({
    url: Api.getPublicKey,
    method: 'GET'
  })
}

export const login = data => {
  return client.request({
    url: Api.login,
    data: data,
    method: 'post'
  })
}

export const logout = data => {
  return client.request({
    url: Api.logout,
    data: data,
    method: 'post'
  })
}

export const getUserInfo = ()=>{
  return client.request({
    url: Api.getUserInfo,
    method: 'post'
  })
}

export const getMenusOfUser = ()=>{
  return client.request({
    url: Api.getMenusOfUser,
    params:{appId:settings.appId},
    method: 'get'
  })
}

export const addReferPageView = ({pt,tag,url})=>{
  return client.request({
    url: Api.addReferPageView,
    data: {partnerNo:pt,partnerLabelValue:tag,url:url},
    disableAutoError: true,
    disableAutoRedirect: true,
    method: "post",
  })
}