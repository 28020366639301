const replaceUserName = (el) => {
  let value = el.value;
  const reg = /[\(\)\uff08\uff09]/g;
  if (value) {
    value = value.replace(reg, "");
    if (value != el.value) {
      el.value = value;
      el.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }
}

export default {
  mounted(el) {
    replaceUserName(el);
  },
  updated(el) {
    replaceUserName(el);
  },
};
