import { ref } from "vue"

export function useFullScreen(){

    const isFull = ref(false);

    function fullScreen(){
        var docElm = document.documentElement;
        if(!isFull.value){
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }else{
                console.log("Not Supported")
            }

        }else{
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }else{
                console.log("Not Supported")
            }
        }
        isFull.value = !isFull.value
    }

    return {
        isFull,
        fullScreen
    }

}