import { message } from "ant-design-vue";
import i18n from "../locale/index.js";

export const getErrorMsgKey = function (errorCode) {
  let msgKey = "error.default";
  if (errorCode) {
    let tempKey = "error." + errorCode;
    if (i18n.global.te(tempKey)) {
      msgKey = tempKey;
    }
  }
  return msgKey;
};

export const analysisErrorMsg = function (data) {
  let errorCode = (data || {})?.errCode;
  let msgKey = getErrorMsgKey(errorCode);
  let filedsStr = "";
  //参数验证的错误
  if (errorCode == "100001") {
    let msgs = (data || {}).errMsgs || {};
    let fileds = Object.keys(msgs);
    if (fileds.length > 0) {
      filedsStr = "(" + fileds.join() + ")";
    }
  }
  return i18n.global.t(msgKey) + filedsStr + (errorCode ? " [" + errorCode + "]" : "")
};

export const showErrorMsg = function (data) {
  message.error(analysisErrorMsg(data));
};
