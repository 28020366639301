<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <a-config-provider :locale="antLocal">
    <router-view />
    <a-back-top />
  </a-config-provider>
</template>

<script>
import { computed, watch, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import siteConfig from "@/siteconfigs";
import { ConfigProvider, BackTop, message } from "ant-design-vue";
import * as i18nLocal from "@/locale/index.js";
import { trySetReferInfo, getQueryRefer } from "@/hooks/refer/index";
import { addReferPageView } from "@/api/modules/user.js"

export default {
  name: "App",
  components: {
    AConfigProvider: ConfigProvider,
    ABackTop: BackTop,
  },
  setup() {
    const { getters } = useStore();
    //const defaultLang = computed(() => getters.language)
    const i18n = useI18n({ useScope: "global" });
    const { getLocaleMessage } = i18n;
    const route = useRoute();
    const antLocal = ref(null);
    watch(
      () => {
        return getters.language;
      },
      async (newVal, oldVal) => {
        await i18nLocal.loadLocaleMessages(i18nLocal.default, newVal);
        antLocal.value = getLocaleMessage(newVal).antLocal;
      },
      {
        immediate: true,
      }
    );

    function updatePageTitle() {
      let title = siteConfig.projectName;
      const { meta } = route;
      if (meta.text && i18n.te(meta.text)) {
        title = i18n.t(meta.text) + `${siteConfig.pageTitleAdditional}`;
      } else if (meta.title) {
        title = meta.title + `${siteConfig.pageTitleAdditional}`;
      }
      if (typeof document !== "undefined") {
        document.title = `${title}`;
      }
    }
    watch(
      () => route.path,
      (path) => {
        updatePageTitle();
      }
    );

    watch(
      () => {
        if (i18n.mode === "legacy") {
          return i18n.locale;
        } else {
          return i18n.locale.value;
        }
      },
      (newLang) => {
        updatePageTitle();
      }
    );

    //全局配置 弹出消息提示
    message.config({
      top: `125px`,
      duration: 2,
      maxCount: 1, //只提示一个
    });

    const processReferInfo = ()=>{
      trySetReferInfo(true);
      var queryRefer = getQueryRefer();
      if(queryRefer)
      {
        addReferPageView(queryRefer).catch(()=>{
          //log error
        });
      }
    }
    processReferInfo();

    return {
      antLocal,
    };
  },
};
</script>
<style lang="less">
// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
