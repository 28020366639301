const fixedStringReplaceAll = () => {
    if (String.prototype.replaceAll) {
        return null;
    }
    String.prototype.replaceAll = function (pattern, replacement) {
        if (!RegExp.prototype.isPrototypeOf(pattern)) {
            return this.replace(new RegExp(pattern,"g"), replacement);
        } else {
            return this.replace(pattern, replacement);
        }
    }
    return "String.prototype.replaceAll"
}


const fixedString = ()=>{
    var arr = [];
    arr.push(fixedStringReplaceAll());
    return arr.filter(x=>!!x);
}
export {
    fixedString
}