import { getVersionJson } from "@/api/modules/version";
import localVersionJson from "/public/version.json";

let keepRunning = false;
let timer = null;
let timeMs = 10 * 60 * 1000;

const _getLocalVersion = () => {
  return localVersionJson.version;
};

const _doQuery = async () => {
  var obj = await getVersionJson();
  return obj?.version;
};

const queryAndCompare = (onVersionNeedUpdate) => {
  _doQuery()
    .then(async (onlineVersion) => {
      return new Promise((resolve, reject) => {
        var localVersion = _getLocalVersion();
        if (onlineVersion != localVersion) {
          if (typeof onVersionNeedUpdate === "function") {
            onVersionNeedUpdate((result) => {
              if (result) {
                stopVersionCompare();
                window.location.reload(true);
              }
              resolve();
            }, () => {
              reject();
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    })
    .finally(() => {
      if (keepRunning) {
        startQueryTimer(onVersionNeedUpdate);
      }
    });
};

const startQueryTimer = (onVersionNeedUpdate, ms) => {
  if (timer) {
    clearTimeout(timer);
  }
  var timeout = timeMs;
  if (ms > 0) {
    timeout = ms;
  }
  timer = setTimeout(() => {
    queryAndCompare(onVersionNeedUpdate);
  }, timeout);
}

export const startVersionCompare = (onVersionNeedUpdate) => {
  keepRunning = true;
  queryAndCompare(onVersionNeedUpdate);
};

export const stopVersionCompare = () => {
  keepRunning = false;
  if (timer) {
    clearTimeout(timer);
  }
};
