import moment from "moment-timezone";
import _ from "lodash";
import { getTimezone } from "../hooks/login/index";

//生成 Auto计划名称
export const createAutoPlanName = function () {
  return "Auto-" + moment(new Date()).format("YYYYMMDDHHmmss");
};

//根据多语言的key,获得text。例如：页面上国家的用法
export const getName = function ({ cnName, enName }, language) {
  let name = null;

  if (language && language == "zh-CN") {
    name = cnName || enName;
  } else {
    name = enName || cnName;
  }
  return name || "";
};

//根据枚举名称和值拼接为可显示的多语言text
export const getEnumLangkey = (enumName, value) => {
  if (value === "" || value === null || value === undefined) {
    return "enum.empty";
  }
  const stringArray = enumName.split("");
  let newNnumName = enumName;
  stringArray.forEach((t) => {
    if (/[A-Z]/.test(t)) {
      newNnumName = newNnumName.replace(t, `_${t.toLowerCase()}`);
    }
  });
  return "enum." + newNnumName + "_" + value;
};

//根据多语言,获得addrees的显示格式
export const getAddressByLanguage = function (
  {
    countryCnName,
    countryEnName,
    provinceCnName,
    provinceEnName,
    cityCnName,
    cityEnName,
    countyCnName,
    countyEnName,
    address,
    address2,
    postCode,
  },
  language,
  separator = ", "
) {
  let addressStr = [address, address2].filter(Boolean).join(separator)
  let strLines = [
    getName({ cnName: countryCnName, enName: countryEnName }, language),
    getName({ cnName: provinceCnName, enName: provinceEnName }, language),
    getName({ cnName: cityCnName, enName: cityEnName }, language),
    getName({ cnName: countyCnName, enName: countyEnName }, language),
    addressStr,
    postCode,
  ];
  if (language && language == "zh-CN") {
    return strLines.filter((x) => x).join(separator);
  } else {
    let postCodeItem = strLines.pop();
    strLines.splice(1, 0, postCodeItem);
    return strLines
      .reverse()
      .filter((x) => x)
      .join(separator);
  }
};

//根据多语言,获得addrees的显示格式,若国家省份有简写，需要显示简写
export const getAddressByLanguageV2 = function (
  {
    countryCnName,
    countryEnName,
    countryIos2,
    provinceCnName,
    provinceEnName,
    provinceAbbr,
    cityCnName,
    cityEnName,
    countyCnName,
    countyEnName,
    address,
    address2,
    postCode,
  },
  language,
  separator = ", "
) {
  let addressStr = [address, address2].filter(Boolean).join(separator)
  var abbr = provinceAbbr?(" (" + provinceAbbr +")"):""
  let strLines = [
    getName({ cnName: countryIos2||countryCnName, enName: countryIos2||countryEnName }, language),
    getName({ cnName: provinceCnName, enName: provinceEnName }, language) + abbr,
    getName({ cnName: cityCnName, enName: cityEnName }, language),
    getName({ cnName: countyCnName, enName: countyEnName }, language),
    addressStr,
    postCode
  ];
  if (language && language == "zh-CN") {
    return strLines.filter((x) => x).join(separator);
  } else {
    //邮编与省份不用分隔符
    let postCodeItem = strLines.pop();
    strLines[1]= strLines[1]+" "+postCodeItem;
    return strLines
      .reverse()
      .filter((x) => x)
      .join(separator);
  }
};

//根据多语言,获得名字的显示格式
export const getNameByLanguage = function (
  { firstName, middleName, lastName },
  language,
  separator = "."
) {
  let names = [lastName, middleName, firstName];
  if (language && language == "zh-CN") {
    return names.filter((x) => x).join('');
  } else {
    return names
      .reverse()
      .filter((x) => x)
      .join(separator);
  }
};

//克转换为千克
export const gToKg = (g) => {
  let val = (parseFloat(g) || 0).toFixed() * 1;
  return val / 1000;
};

//千克转换为克
export const kgToG = (kg) => {
  let val = (parseFloat(kg) || 0) * 1000;
  return val.toFixed() * 1;
};

//立方厘米转立方米
export const cmCubicToM = (cubicCm) => {
  let val = (parseFloat(cubicCm) || 0).toFixed() * 1;
  return val / 1000000;
};

//立方米转立方厘米
export const mCubicToCm = (cubicM) => {
  let val = (parseFloat(cubicM) || 0) * 1000000;
  return val.toFixed() * 1;
};

//验证统一社会信用代码，必须为大写
export const checkSocialCreditCode = (sccCode) => {
  if (!sccCode) {
      return false;
  }
  var patrn = /^[0-9A-Z]{18}$/;
  //18位校验及大写校验  
  if (!patrn.test(sccCode)) {
      return false;
  } else {
      const charArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "T", "U", "W", "X", "Y"];
      const weightArr = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];
      let checkSum = 0;
      for (let i = 0; i < sccCode.length - 1; i++) {
          let chr = sccCode[i];
          let index = charArr.indexOf(chr)
          checkSum = checkSum + index * weightArr[i];
      }
      checkSum = (31 - checkSum % 31) % 31;
      return charArr[checkSum] == sccCode.slice(-1);
  }
}

// 当前时区的时间转为UTC时间
export const currentTimeToUtc = (date, targetTimezone = null) => {
  if (!date) return;
  const userTimezone = targetTimezone || getTimezone();
  if(typeof(date) === "object" && date._isAMomentObject){
    return moment.tz(date.format("YYYY-MM-DD HH:mm:ss"), userTimezone).utc().format();
  }
  
  return moment.tz(date, userTimezone).utc().format();
}

// 防抖
export const debounce = (func, wait = 0) => {
  if (typeof func !== 'function') {
    throw new TypeError('need a function arguments')
  }
  let timeid = null;
  let result;

  return function () {
    let context = this;
    let args = arguments;

    if (timeid) {
      clearTimeout(timeid);
    }
    timeid = setTimeout(function () {
      result = func.apply(context, args);
    }, wait);

    return result;
  }
}

/**
 * @description 四舍五入
 * @param {*} number 操作数字
 * @param {*} n 保留的位数
 * @returns 
 */
 export const roundNumber = (number = 0, n = 2) => {
  number = parseFloat(number)
  if (n > 20 || n < 0) {
    throw new RangeError('toFixed() digits argument must be between 0 and 20');
  }

  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString();
  }

  if (typeof (n) == 'undefined' || n == 0) {
    return (Math.round(number)).toString();
  }

  let result = number.toString();
  const arr = result.split('.');

  // 整数的情况
  if (arr.length < 2) {
    result += '.';
    for (let i = 0; i < n; i += 1) {
      result += '0';
    }
    return result;
  }
  const integer = arr[0];
  const decimal = arr[1];

  if (decimal.length == n) {
    return result;
  }

  if (decimal.length < n) {
    for (let i = 0; i < n - decimal.length; i += 1) {
      result += '0';
    }
    return result;
  }
  result = integer + '.' + decimal.substr(0, n);
  const last = decimal.substr(n, 1);

  // 四舍五入，转换为整数再处理，避免浮点数精度的损失
  if (parseInt(last, 10) >= 5) {
    const x = Math.pow(10, n);
    result = (Math.round((parseFloat(result) * x)) + 1) / x;
    result = result.toFixed(n);
  }
  return result;
}

/**
 * @description 是否正整数
 * @param {*} val 
 * @returns 
 */
export const isInteger = (val) => {
  
  let reg = /^\+?[1-9][0-9]*$/;
  return reg.test(val)
}

export const getLinkman = (warehouse, symbol = '-') => {
  let concat = ''
  if (warehouse) {
    concat = (warehouse?.linkMan ? (warehouse?.linkMan + ', ') : '') + (warehouse?.phoneCountryCode ? (warehouse?.phoneCountryCode + '-') : '') + (warehouse?.linkManPhone ?? '')
  }
  concat = concat.trim()
  return concat || symbol
}

export const getLinkUser = (warehouse, symbol = '-') => {
  let concat = ''
  if (warehouse) {
    concat = (warehouse?.linkUserName ? (warehouse?.linkUserName + ', ') : '') + (warehouse?.phoneCountryCode ? (warehouse?.phoneCountryCode + '-') : '') + (warehouse?.linkPhone ?? '')
  }
  concat = concat.trim()
  return concat || symbol
}

// 替换中文的圆括号为英文圆括号
export const replaceBrackets = (str = '') => {
  if (str) {
    return str
      .replace(new RegExp('\uff08', 'g'), '')
      .replace(new RegExp('\uff09', 'g'), '')
      .replace(new RegExp('\\u0028', 'g'), '')
      .replace(new RegExp('\\u0029', 'g'), '');
  }
  return str
}

const globalReg = {
  'DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
  'SY': /^(!?(\+?963)|0)?9\d{8}$/,
  'SA': /^(!?(\+?966)|0)?5\d{8}$/,
  'US': /^(\+?1[ ]?)?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  'CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'DE': /^(\+?49[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
  'DK': /^(\+?45)?(\d{8})$/,
  'GR': /^(\+?30)?(69\d{8})$/,
  'AU': /^(\+?61|0)4\d{8}$/,
  'GB': /^(\+?44|0)7\d{9}$/,
  'IN': /^(\+?91|0)?[789]\d{9}$/,
  'NZ': /^(\+?64|0)2\d{7,9}$/,
  'ZA': /^(\+?27|0)\d{9}$/,
  'ZM': /^(\+?26)?09[567]\d{7}$/,
  'ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  "FI": /^(\+?358|0)\s?(4(0|1|2|4|5)?|50)\s?(\d\s?){4,8}\d$/,
  'FR': /^(\+?33|0)[67]\d{8}$/,
  'IL': /^(\+972|0)([23489]|5[0248]|77)[1-9]\d{6}/,
  'HU': /^(\+?36)(20|30|70)\d{7}$/,
  'IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  'JP': /^(\+?81|0)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$/,
  'MY': /^(\+?6?01){1}(([145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
  'BE': /^(\+?32|0)4?\d{8}$/,
  'NO': /^(\+?47)?[49]\d{7}$/,
  'PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  'BR': /^(\+?55|0)\-?[1-9]{2}\-?[2-9]{1}\d{3,4}\-?\d{4}$/,
  'PT': /^(\+?351)?9[1236]\d{7}$/,
  'RU': /^(\+?7|8)?9\d{9}$/,
  'RS': /^(\+3816|06)[- \d]{5,9}$/,
  'TR': /^(\+?90|0)?5\d{9}$/,
  'VN': /^(\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$/,
  'MX': /^(\+?52)?[\d ]{9,22}$/,
  'CN': /^(\+?0?86\-?)?((1[3456789]\d{9})|((\+?886\-?|0)?9\d{8})|((\+?852\-?)?[5689]\d{3}\-?\d{4})|((853)?6\d{7}))$/,  // 大陆|台湾|香港|澳门
}

/**
 * 验证当前国家的手机号码
 * @param {*} countryCode IOS2
 * @param {*} phone 手机号码   //区号+号码
 */
export const verifyMobilePhoneNumber = (countryCode, phone) => {
  var reg = globalReg[countryCode]||/^(\+?[\d])[\d\-]{0,18}[\d]{0,1}$/;
  return reg.test && reg.test(phone);
};

/**
 * 通用验证手机号码
 * @param {*} phone 手机号码
 */
export const isValidGenericPhoneNumber = (phone = '') => {
  if (!phone) {
    return false
  }
  phone = phone.replaceAll('（', '(').replaceAll('）', ')');
  return /^(?=.{7,32}$)[\+]?((([0-9]+)|(\([0-9]+\))+)[\s\-]?)*[0-9]+$/.test(phone);
};

/**
 * 通用验证邮箱
 * @param {*} email 
 */
export const isValidEmail =(email="")=>{
  if (!email) {
    return false
  }
  return /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(email);

}

/**
 * 获取时间字符串
 */
export const dateString = (date = new Date(), format = 'YYYYMMDDHHmmss') => {
  return moment(new Date(date)).format(format)
};

/**
 * @description 对字符串集合随机排列，随机输出指定的长度
 * @param {*} length
 * @returns 
 */
export const randomString = (length = 6) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (var i = length; i > 0; --i) {
    result += str[Math.floor(Math.random() * str.length)];
  }
  return result;
}

// 遍历对象并赋值
export const setFormStateValue = (form, result) => {
  if (_.isPlainObject(form)) {
    Object.keys(form).forEach((key) => {
      if (Object.hasOwnProperty.call(result, key)) {
        if (_.isPlainObject(form[key])) {
          if (result[key]) {
            setFormStateValue(form[key], result[key]);
          }
        } else if (_.isArray(form[key])) {
          if (result[key]) {
            form[key] = result[key];
          }
        } else {
          form[key] = result[key];
        }
      }
    })
  }
}