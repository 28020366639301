const replaceBoxNo = (el) => {
  // 非formItem组件，且input组件使用allow-clear
  let inputEl = el.getElementsByTagName('input')[0]
  let value = inputEl.value;
  const reg = /[^\w\/\-\\]/g;
  if (value) {
    value = value.replace(reg, "");
    if (value != inputEl.value) {
      inputEl.value = value;
      inputEl.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }
}

export default {
  mounted(el) {
    replaceBoxNo(el);
  },
  updated(el) {
    replaceBoxNo(el);
  },
};
