const replaceAddress = (el) => {
  let value = el.value;
  const reg = /[\/\\\@]/g;
  if (value) {
    value = value.replace(reg, "");
    if (value != el.value) {
      el.value = value;
      el.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }
}

export default {
  mounted(el) {
    replaceAddress(el);
  },
  updated(el) {
    replaceAddress(el);
  },
};
