<template>
  <a-badge :dot="state.newsCount > 0">
    <BellOutlined />
  </a-badge>
</template>

<script>
import { defineComponent, reactive, onBeforeUnmount, watch } from 'vue';
import { Badge } from 'ant-design-vue';
import { useStore } from 'vuex';
import { getIsNoReadCount } from '@/api/modules/account/index.js';

export default defineComponent({
  components: {
    ABadge: Badge,
  },
  setup () {
    const { getters, dispatch } = useStore();

    const state = reactive({
      newsCount: 0,
      // 获取公告数量倒计时
      countdown: 2 * 60 * 1000,
      countTimer: null,
    })

    watch(() => getters.refreshNews, (val) => {
      if (val) {
        getIsNoReadCount()
        .then(({ result }) => {
          dispatch("user/setRefreshNews", false);
          if (typeof result === 'number') {
            state.newsCount = result;
          } else {
            state.newsCount = 0;
          }
        })
        .catch(() => {})
      }
    });

    let isLeveled = false;

    const getNewsCount = () => {
      if (!getters.userInfo) {
        if (state.countTimer) {
          clearTimeout(state.countTimer);
        }
        state.countTimer = setTimeout(getNewsCount, state.countdown);
        return;
      }
      getIsNoReadCount()
        .then(({ result }) => {
          if (typeof result === 'number') {
            state.newsCount = result;
          } else {
            state.newsCount = 0;
          }
        })
        .catch(() => {})
        .finally(() => {
          if (isLeveled) {
            return;
          }
          if (state.countTimer) {
            clearTimeout(state.countTimer);
          }
          state.countTimer = setTimeout(getNewsCount, state.countdown);
        });
    }
    
    getNewsCount();

    onBeforeUnmount(() => {
      isLeveled = true;
    })

    return {
      state,
    }
  }
})
</script>

<style scoped>

</style>