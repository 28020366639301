import client from "../../client";

const Api = {
  //注册账户
  registerAccount: "/api/ProductService/Account/RegisterAccount",

  //注册商铺,提交公司信息
  registerShop: "/api/ProductService/Account/RegisterShop",

  //重新提交公司信息
  reviewShopInfoAgain: "/api/ProductService/Account/ReviewShopInfoAgain",

  //更新密码
  updatePwd: "/api/ProductService/Account/UpdatePwd",

  // 检查手机号是否有账号
  checkPhoneNumberIsExist: "/api/ProductService/Account/CheckPhoneNumberIsExist",
  // 忘记密码获取手机号验证码
  getForgetPwdCode: "/api/ProductService/ValidCode/ForgetPwdCode",
  // 重设密码
  resetPassword: "/api/ProductService/Account/ResetPassword",

  // 获取所有时区
  getTimeZoneList: "/api/BasicDataService/CustomTimeZone/GetList",
  // 更改账户时区
  updateTimezone: "/api/URCService/User/UpdateTimezone",

  // 获取未读信息
  getIsNoReadCount: "/api/ProductService/ShopUserAnnounce/GetIsNoReadCount",
  // 获取用户消息列表
  getShopUserAnnounceListPaged: "/api/ProductService/ShopUserAnnounce/GetListPaged",
  // 将选择消息标记为已读
  readShopUserAnnounce: "/api/ProductService/ShopUserAnnounce/Read",
  // 将所有消息标记为已读
  readAllShopUserAnnounce: "/api/ProductService/ShopUserAnnounce/ChangeAllRead",
  // 获取单个公告详情
  getAnnounce: "/api/ProductService/Announce/Get",
}

export const registerAccount = ({
  phoneNumber,
  passWord,
  timezone,
  validCode,
  countryId,
  phoneCountryCode,
  pt,
  tag
}) => {
  return client.request({
    url: Api.registerAccount,
    data: {
      phoneNumber,
      passWord,
      timezone,
      validCode,
      countryId,
      phoneCountryCode,
      pt,
      tag
    },
    method: "POST"
  });
}

export const registerShop = ({
  shopName,
  businessLicense,
  businessLicenseCode,
  realName
}) => {
  return client.request({
    url: Api.registerShop,
    data: {
      shopName,
      businessLicense,
      businessLicenseCode,
      realName
    },
    method: "POST"
  });
}

export const reviewShopInfoAgain = ({
  shopName,
  businessLicense,
  businessLicenseCode,
  realName
}) => {
  return client.request({
    url: Api.reviewShopInfoAgain,
    data: {
      shopName,
      businessLicense,
      businessLicenseCode,
      realName
    },
    method: "POST"
  });
}

export const updatePwd = (data) => {
  return client.request({
    url: Api.updatePwd,
    data,
    method: "POST",
  });
};

export const checkPhoneNumberIsExist = (data) => {
  return client.request({
    url: Api.checkPhoneNumberIsExist,
    data,
    method: "POST",
    withoutAuth:true
  });
};

export const getForgetPwdCode = (data) => {
  return client.request({
    url: Api.getForgetPwdCode,
    data,
    method: "POST",
    withoutAuth:true
  });
};

export const resetPassword = (data) => {
  return client.request({
    url: Api.resetPassword,
    data,
    method: "POST",
    withoutAuth:true
  });
};

export const getTimeZoneList = (data) => {
  return client.request({
    url: Api.getTimeZoneList,
    data,
    method: "POST",
  });
};

export const updateTimezone = (data) => {
  return client.request({
    url: Api.updateTimezone,
    data,
    method: "PUT",
  });
};

export const getIsNoReadCount = () => {
  return client.request({
    url: Api.getIsNoReadCount,
    method: "GET",
  });
};

export const getShopUserAnnounceListPaged = (data) => {
  return client.request({
    url: Api.getShopUserAnnounceListPaged,
    data,
    method: "POST",
  });
};

export const readShopUserAnnounce = (data) => {
  return client.request({
    url: Api.readShopUserAnnounce,
    data,
    method: "POST",
  });
};

export const readAllShopUserAnnounce = (data) => {
  return client.request({
    url: Api.readAllShopUserAnnounce,
    data,
    method: "POST",
  });
};

export const getAnnounce = (params) => {
  return client.request({
    url: Api.getAnnounce,
    params,
    method: "GET",
  });
};