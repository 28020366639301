import {nameKeys} from "../consts/index"

const getters = {
    theme: state => state.app.theme,
    layout: state => state.app.layout,
    color: state => state.app.color,
    collapsed: state => state.app.collapsed,
    tab: state => state.app.tab,
    routerActive: state => state.app.routerActive,
    // sideWitch: state => state.app.sideWitch,
    // collapsedWidth: state => state.app.collapsedWidth,
    fixedHeader: state => state.app.fixedHeader,
    routes: state => state.app.routes,
    // colorList: state => state.app.colorList,
    routerAnimate: state => state.app.routerAnimate,
    language: state => state.app.language,
    isMobile: state => state.app.isMobile,
    keepAlive: state=> state.app.keepAlive,
    // token: state =>
    //   state.user.token
    //     ? state.user.token
    //     : localStorage.getItem(nameKeys.token)
    //     ? localStorage.getItem(nameKeys.token)
    //     : "",
    // menu: state =>
    //   state.user.userRoutes.length !== 0
    //     ? state.user.userRoutes
    //     : localStorage.getItem(nameKeys.userRoutes)
    //     ? JSON.parse(localStorage.getItem(nameKeys.userRoutes))
    //     : "",
    token: state => state.user.token,
    timezone: state => state.user.userTimezone,
    menu: state => state.user.userRoutes,
    userInfo: state => state.user.userInfo,
    refreshNews: state => state.user.refreshNews,
  };
  export default getters;
  