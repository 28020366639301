import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { findParents } from '@/utils/common';


export function useMenu(){
    const { getters } = useStore();
    const route = useRoute();
    const selectedKeys = ref([]);
    const menuStore = computed(() => getters.menu);
    const openKeys = ref([]);
    // const lastOpenKeys = ref([]);
    const menus = ref(menuStore.value);

    watch(()=>route.path,
    (path)=>{
        selectedKeys.value = [path];            
        if(getters.layout !== "layout-head" && !getters.collapsed )
        {
            let partents = findParents(menus.value,path);
           // console.log(partents);
            openKeys.value = partents;
            // lastOpenKeys.value = partents;
        }
    },
    {immediate: true});

    

    function openChange(keys) {
        
    //   if(getters.layout !== "layout-head" )
    //   {
    //     const lastPath = keys.find(key => lastOpenKeys.value.indexOf(key) === -1);
    //     let result = findParents(menus.value, lastPath, true);
    //     openKeys.value = result;
    //     lastOpenKeys.value = result;
    //   }
      
    }



    return {
        selectedKeys, openKeys, openChange, menus
    }
}